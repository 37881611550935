<template>
  <section modal-content v-editable="blok" :class="{ open: open }" :id="blok.id">
    <div class="modal-backdrop" @click="closeModal"></div>
    <div class="modal-card">
      <div class="modal-close" @click="closeModal"></div>
      <div class="modal-body">
        <Vue3RuntimeTemplate :template="content" />
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/modal-content.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const open = ref(false);

import Vue3RuntimeTemplate from "vue3-runtime-template";

const content = computed(() => useRenderRichText(props?.blok?.content || {}));
const closeModal = () => {
  open.value = false;
};
const openModal = (event) => {
  event.preventDefault();
  open.value = true;
};

onMounted(() => {
  if (import.meta.client) {
    document.querySelector("a[gref]");

    document.querySelectorAll(`[href$='#${props.blok.id}']`).forEach((btn) => {
      btn.addEventListener("click", openModal);
    });
  }
});
</script>
